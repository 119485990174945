/* App.css */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.data-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}


.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* High z-index to ensure it appears in front */
}


.custom-input-group-text {
  width: 155px;
  /*color: #ffffff; /* Text color */
  /*background-color: #007bff; /* Background color */
  font-weight: 'bold';
  /*className="custom-input-group-text"
  style={{ width: '155px', color: '#ffffff', backgroundColor: '#007bff', fontWeight: 'bold' }}
  */
}
